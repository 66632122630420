<template>
  <div class="page-main">
    <div class="page-header">
      <div class="title">{{ pageTitle }}</div>
      <div class="handle">
        <el-button @click="$router.push('/home/account/recharge')" class="handle-button" type="text">
          返回概览
        </el-button>
      </div>
    </div>

    <div class="page-content">
        <div class="header">
          <div class="company">{{infoData.company_name}}</div>
          <div class="money">{{infoData.amount||'--'}}</div>
        </div>

        <div class="detail-info">
          <div class="info-line">
            <span>交易状态</span>
            <span class="right-blue" v-if="infoData.is_pay=='n'">核对中，请耐心等待</span>
            <span class="right-success" v-else>交易已完成</span>
          </div>
          <div class="info-line">
            <span>状态说明</span>
            <span>{{statusName[infoData.status]}}</span>
          </div>
          <div class="info-line">
            <span>交易时间</span>
            <span>{{infoData.pay_at||'--'}}</span>
          </div>
          <div class="info-line">
            <span>交易类型</span>
            <span>{{infoData.payment_type||'--'}}</span>
          </div>
          <div class="info-line">
            <span>凭证号</span>
            <span>{{infoData.sn||'--'}}</span>
          </div>
          <div class="info-line unline">
            <span>备注</span>
            <span>{{infoData.memo||'--'}}</span>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {userRechargeInfo} from "@/api/user";

export default {
  name: "recharge-detail",
  data() {
    return {
      pageTitle: '充值详情',
      detail_sn: '',
      infoData: {},
      statusName: {
        1: '取消',
        2: '支付失败',
        10: '未支付',
        20: '已支付',
      },
    }
  },
  created() {
    if (this.$route.query.sn) {
      this.detail_sn = this.$route.query.sn
    }
  },
  mounted() {
    this.loadInfo(this.detail_sn)
  },
  methods: {
    async loadInfo(sn) {
      const res = await userRechargeInfo({ sn: sn });
      if (res.statusCode == 200) {
        this.infoData = res.data;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  background-color: #FFFFFF;
  min-height: 930px;

  // 头部
  .page-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0px 40px;
    height: 80px;
    border-bottom: 1px solid #EDEDED;

    .title {
      font-size: 20px;
      color: #000000;
    }

    .handle {
      font-size: 16px;

      .handle-button {
        color: #00ace6;
        font-size: 16px !important;
      }
    }
  }

  // 主体
  .page-content {
    padding: 0px 300px 40px 300px;

    .header {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .company {
        font-size: 20px;
        font-weight: bold;
        color: #575757;
      }

      .money {
        font-size: 24px;
        font-weight: bold;
        color: #575757;
        margin-top: 20px;
      }
    }

    .detail-info {

      .info-line {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EEEEEE;
        font-size: 16px;
        color: #575757;

        .right-blue {
          color: #00B4FF;
        }

        &.unline {
          border-bottom: unset;
        }
      }

    }
  }
}
</style>
